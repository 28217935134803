<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-sm-6 p-md-0">
        <div class="welcome-text">
          <h4>Applications</h4>
        </div>
      </div>
      <div class="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item active">Applications</li>
        </ol>
      </div>
    </div>
    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <!-- <div class="card-header">
            <h4 class="card-title">List All</h4>
            <router-link v-can="'add_applications'" :to="{ name: 'Applications-Create' }" class="btn btn-primary">Add
              New</router-link>
          </div> -->
          <div class="card-body">
            <div v-userRole="'Admin,Employee'" class="search-block row" style="padding-bottom: 15px;">
              <div class="col-md-12">
                <form @submit.prevent="submitSearchForm()" class="form-inline">
                  <!-- Application Status -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Application Status</label>
                    <select class="form-control" name="status" v-model="search_fields.status">
                      <option value="" selected>All</option>
                      <option v-for="(status, index) in lists.statuses" :key="index" :value="index">{{ status }}
                      </option>
                    </select>
                  </div>
                  <!-- Payment Status -->
                  <div class="form-group mb-2 mr-2">
                    <label class="mr-2">Payment Status</label>
                    <select class="form-control" name="payment_status" v-model="search_fields.payment_status">
                      <option value="" selected>All</option>
                      <option v-for="(status, index) in lists.payment_statuses" :key="index" :value="index">{{ status }}
                      </option>
                    </select>
                  </div>
                  <!-- Free search input -->
                  <div class="form-group mb-2 mr-2">
                    <input type="text" class="form-control" v-model="search_fields.free_search_input"
                      name="free_search_input" placeholder="Any Data">
                  </div>
                  <div class="form-group mb-2 mr-2">
                    <button name="search" type="submit" class="btn btn-primary" value="Filter" onclick="this.blur();">
                      Filter
                    </button>
                  </div>

                </form>
              </div>
            </div>
            <div class="table-responsive">
              <EasyDataTable :loading="loading" :rows-per-page="10" :rows-items="[25, 50, 100]" :headers="headers"
                :items="applications" alternating buttons-pagination>
                <template #item-actions="{ id, is_editable_by_parent, }">
                  <div>
                    <router-link v-can="'edit_applications'"
                      v-if="(hasAnyRole('Applicant,Parent,Student') && is_editable_by_parent == 'Yes') || !hasAnyRole('Applicant,Parent,Student')"
                      :to="{ name: 'Applications-Edit', params: { 'id': id } }" title="Edit" class="mr-4"><i
                        class="fa fa-pencil color-muted"></i>
                    </router-link>
                    <a v-can="'delete_applications'" @click.prevent="deleteApp(id)" href="javascript:void()"
                      title="Delete"><i class="fa fa-close color-danger"></i></a>
                  </div>
                </template>
              </EasyDataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useApplication from "@/composables/applications";
import {
  ref,
  inject,
  onMounted,
  onBeforeMount,
  reactive,
} from "vue";
import {
  useRouter
} from 'vue-router';
import EasyDataTable from "vue3-easy-data-table";
import 'vue3-easy-data-table/dist/style.css';

export default {

  components: {
    EasyDataTable,
  },

  setup() {

    const swal = inject('$swal');
    const hasPermission = inject('hasPermission');
    const hasAnyRole = inject('hasAnyRole');
    const router = useRouter();
    const loading = ref(true);

    const search_fields = reactive({
      'status': '',
      'payment_status': '',
      'free_search_input': '',

    })

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasPermission('access_applications'))
        router.push({
          name: '401'
        })
    });

    const {
      applications,
      getApplications,
      deleteApplication,
      getApplicationStatuses,
      lists
    } = useApplication();

    onMounted(() => {
      getApplications().then(() => {
        loading.value = false;
      });
      getApplicationStatuses();
    });

    const headers = ref([
      { text: "ID", value: "id" },
      { text: "Reference Number", value: "ref_num", sortable: true },
      { text: "Application Status", value: "status", sortable: true },
      { text: "Payment Status", value: "payment_status", sortable: true },
      { text: "Student Name", value: "firstname_en", sortable: true },
      { text: "Applied At", value: "created_at", sortable: true },
      { text: "Actions", value: "actions", sortable: true },
    ]);

    const deleteApp = async (id) => {
      swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {

        if (result.isConfirmed) {
          deleteApplication(id).then(() => {
            swal.fire(
              'Deleted!',
              'The data has been deleted successfully.',
              'success'
            )
            getApplications();
          });
        }
      })
    }

    const submitSearchForm = async () => {
      loading.value = true;
      await getApplications(search_fields).then(() => {
        loading.value = false;
      });
    }

    return {
      loading,
      headers,
      applications,
      lists,
      search_fields,
      hasAnyRole,
      deleteApp,
      submitSearchForm
    }

  },


};
</script>

<style>
.vue3-easy-data-table__header tr th .header {
  position: unset !important;
  color: black !important;
  text-align: left !important;
  background-color: #fafafa !important;
  padding-left: 1px !important;
  height: 2rem !important;
}
</style>
 